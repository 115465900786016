.dialog {
  &-enter {
    opacity: 0.01;
    transform: scale(1.1);

    &-active {
      opacity: 1;
      transform: scale(1);
      transition: all 300ms;
    }
  }

  &-exit {
    opacity: 1;
    transform: scale(1);

    &-active {
      opacity: 0.01;
      transform: scale(1.1);
      transition: all 300ms;
    }
  }
}

.menu {
  &-enter {
    opacity: 0.01;
    transform: scale(1.1);

    &-active {
      opacity: 1;
      transform: scale(1);
      transition: all 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
  }

  &-exit {
    opacity: 1;
    transform: scale(1);

    &-active {
      opacity: 0.01;
      transform: scale(1.1);
      transition: all 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
  }
}
